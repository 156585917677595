<!--
 * @Description: 人行道停车运营管理 运营管理 过车记录 carBeforeRecord
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-02 09:32:48
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainBody">
    <!-- 入口查询 -->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchFormIn">
        <el-row>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="车牌号">
              <el-input v-model="searchFormIn.plateNumber"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="入场时间">
              <el-date-picker v-model="inTime"
                              type="daterange"
                              align="right"
                              unlink-panels
                              value-format="yyyy-MM-dd HH:mm:ss"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']"
                              :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="停车场">
              <el-select class="search-input"
                         clearable
                         v-model="searchFormIn.parkId"
                         placeholder="全部">
                <el-option v-for="(item, index) in parkNameList"
                           :key="index"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-row class="topButton">
              <el-button type="primary"
                         @click="handleClickInSearch">查 询</el-button>
              <el-button type="info"
                         style="margin-left:17px"
                         @click="handleClickInReset">重 置</el-button>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
    </el-row>
    <!-- 入口表格 -->
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>入口监测</span>
      </el-row>
      <el-row class="tableContent">
        <el-row class="image">
          <el-image v-if="selectedInRecord.inImage"
                    :src="$imgBaseUrl+selectedInRecord.inImage"
                    :preview-src-list="insrcList"
                    :fit="fit"
                    style="width:100%;height:100%">
          </el-image>
          <div v-else
               style="width:90%;height:90%;display:flex;justify-content: center;align-items: center;">暂无图片</div>
        </el-row>
        <el-row class="table">
          <dt-table :tableColumnList="tableColumnList"
                    :data="tableList"
                    :map="listMap"
                    :tableConfig='tableConfig'
                    :paginationConfig='paginationConfig'
                    @getList="getList"
                    :tableFun="tableFun">
            <template slot="operating">
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button @click="showDetail(scope.row)"
                             type="text"
                             size="medium">查看详情</el-button>
                </template>
              </el-table-column>
            </template>
          </dt-table>
        </el-row>
      </el-row>
    </el-row>

    <!--出口-上半部查询-->
    <el-row class="topSelectTwoLine"
            style="margin-top:15px">
      <el-form :inline="true"
               :model="searchFormOut">
        <el-row>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="车牌号">
              <el-input v-model="searchFormOut.plateNumber"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="出场时间">
              <el-date-picker v-model="outTime"
                              type="daterange"
                              align="right"
                              unlink-panels
                              value-format="yyyy-MM-dd HH:mm:ss"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']"
                              :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="停车场">
              <el-select class="search-input"
                         clearable
                         v-model="searchFormOut.parkId"
                         placeholder="全部">
                <el-option v-for="(item, index) in parkNameList"
                           :key="index"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-row class="topButton">
              <el-button type="primary"
                         @click="search">查 询</el-button>
              <el-button type="info"
                         style="margin-left:17px"
                         @click="handleClickOutReset">重 置</el-button>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
    </el-row>
    <!--出口-表格-->
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>出口监测</span>
      </el-row>
      <el-row class="tableContent">
        <el-row class="image">
          <el-image v-if="selectedOutRecord.outImage"
                    :src="$imgBaseUrl+selectedOutRecord.outImage"
                    :preview-src-list="outsrcList"
                    :fit="fit"
                    style="width:100%;height:100%">
          </el-image>
          <el-row v-else
                  style="width:100%;height:100%;display:flex;justify-content: center;align-items: center;">
            暂无图片
          </el-row>
        </el-row>
        <el-row class="table">
          <dt-table :tableColumnList="outTableColumnList"
                    :data="outTableList"
                    :paginationConfig='paginationConfigOut'
                    :map="listMapOut"
                    :tableConfig='tableConfig'
                    @getList="getListOut"
                    :tableFun="tableFunOut"
                    style="cursor: pointer;">
            <template slot="operating">
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button @click="showDownDetail(scope.row)"
                             type="text"
                             size="medium">查看详情</el-button>
                </template>
              </el-table-column>
            </template>
          </dt-table>
        </el-row>
      </el-row>
    </el-row>

    <!-- 入口详情弹窗 -->
    <el-dialog title="详情"
               :visible.sync="upDetailDialogVisible"
               append-to-body
               @close="downshow = 0">
      <el-row style="display: flex">
        <el-row style="width: 30%;">
          <el-image style="width: 95%; height:100%;"
                    :src="$imgBaseUrl+selectedInRecord.inImage"
                    :preview-src-list="insrcList"
                    :fit="fit" />
        </el-row>
        <el-row>
          <el-row style="margin-top:10px;color:#79baf7;font-size:16px;"> 停车场:{{ selectedInRecord.parkName }}</el-row>
          <el-row style="margin-top:10px;color:#79baf7;font-size:16px;"> 通过设备:{{ selectedInRecord.orderType }}</el-row>
          <el-row style="margin-top:10px;color:#79baf7;font-size:16px;"> 入场时间:{{ selectedInRecord.inTime }}</el-row>
          <el-row style="margin-top:10px;color:#79baf7;font-size:16px;"> 车牌号:{{ selectedInRecord.plateNumber }}</el-row>
          <el-row style="margin-top:10px;color:#79baf7;font-size:16px;"> 车牌颜色:{{ selectedInRecord.plateColor }}</el-row>
          <el-button type="primary"
                     @click="getOutRecord(selectedInRecord.orderSequence)">追踪出场记录
          </el-button>
        </el-row>
      </el-row>
      <el-row v-if="downshow === 1"
              style="display: flex;margin-top: 20px">
        <el-row style="width: 30%">
          <el-image style="width: 95%; height:90%;"
                    :src="$imgBaseUrl+selectedOutRecord2.outImage"
                    :preview-src-list="outsrcList"
                    :fit="fit" />
        </el-row>
        <el-row>
          <el-row style="margin-top:10px;color:#79baf7;font-size:16px;">停车场:{{ selectedOutRecord2.parkName }}</el-row>
          <el-row style="margin-top:10px;color:#79baf7;font-size:16px;">
            通过设备:{{ selectedOutRecord2.orderType }}
          </el-row>
          <el-row style="margin-top:10px;color:#79baf7;font-size:16px;">出场时间:{{ selectedOutRecord2.outTime }}</el-row>
          <el-row style="margin-top:10px;color:#79baf7;font-size:16px;">车牌号:{{ selectedOutRecord2.plateNumber }}</el-row>
          <el-row style="margin-top:10px;color:#79baf7;font-size:16px;">车牌颜色:{{ selectedOutRecord2.plateColor }}</el-row>
        </el-row>
      </el-row>
    </el-dialog>

    <!-- 出口详情弹窗 -->
    <el-dialog title="详情"
               :visible.sync="downDetailDialogVisible"
               append-to-body
               @close="upshow = 0">
      <el-row style="display: flex">
        <el-row style="width: 30%;">
          <el-image :src="$imgBaseUrl+selectedOutRecord.outImage"
                    style="width: 95%; height:90%;"
                    :preview-src-list="outsrcList"
                    :fit="fit" />
        </el-row>
        <el-row>
          <el-row style="margin-top:10px;color:#79baf7;font-size:16px;">停车场:{{ selectedOutRecord.parkName }}</el-row>
          <el-row style="margin-top:10px;color:#79baf7;font-size:16px;"> 通过设备:{{ selectedOutRecord.orderType }}</el-row>
          <el-row style="margin-top:10px;color:#79baf7;font-size:16px;">出场时间:{{ selectedOutRecord.outTime }}</el-row>
          <el-row style="margin-top:10px;color:#79baf7;font-size:16px;">车牌号:{{ selectedOutRecord.plateNumber }}</el-row>
          <el-row style="margin-top:10px;color:#79baf7;font-size:16px;">车牌颜色:{{ selectedOutRecord.plateColor }}</el-row>
          <el-button type="primary"
                     @click="getInRecord(selectedOutRecord.orderSequence)">追踪入场记录</el-button>
        </el-row>
      </el-row>
      <el-row v-if="upshow === 1"
              style="display: flex;margin-top: 20px">
        <el-row style="width: 30%">
          <el-image :src="$imgBaseUrl+ selectedInRecord2.inImage"
                    style="width: 95%; height:90%;"
                    :preview-src-list="insrcList"
                    :fit="fit" />
        </el-row>
        <el-row>
          <el-row style="margin-top:10px;color:#79baf7;font-size:16px;"> 停车场:{{ selectedInRecord2.parkName }}</el-row>
          <el-row style="margin-top:10px;color:#79baf7;font-size:16px;"> 通过设备:{{ selectedInRecord2.orderType }}</el-row>
          <el-row style="margin-top:10px;color:#79baf7;font-size:16px;"> 入场时间:{{ selectedInRecord2.inTime }}</el-row>
          <el-row style="margin-top:10px;color:#79baf7;font-size:16px;"> 车牌号:{{ selectedInRecord2.plateNumber }}</el-row>
          <el-row style="margin-top:10px;color:#79baf7;font-size:16px;"> 车牌颜色:{{ selectedInRecord2.plateColor }}</el-row>
        </el-row>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    //这里存放数据
    return {
      tableFun: { 'row-click': this.handleSelection },
      tableFunOut: { 'row-click': this.handleOutSelection },
      paginationConfig: { layout: 'prev,pager,next,jumper', total: 0 },
      paginationConfigOut: { layout: 'prev,pager,next,jumper', total: 0 },
      tableConfig: { border: true },
      listMap: { pageNum: 1, pageSize: 4 },
      listMapOut: { pageNum: 1, pageSize: 4 },
      tableColumnList: [
        {
          prop: 'inTime',
          label: '入场时间',
        },
        {
          prop: 'plateNumber',
          label: '车牌号',
        },
        {
          prop: 'parkName',
          label: '停车场名称',
        }
      ],
      outTableColumnList: [
        {
          prop: 'outTime',
          label: '出场时间',
        },
        {
          prop: 'plateNumber',
          label: '车牌号',
        },
        {
          prop: 'parkName',
          label: '停车场名称',
        }
      ],
      tableList: { list: [], },
      outTableList: { list: [], },
      searchFormIn: {},
      searchFormOut: {},
      pageNumIn: 1,
      pageNumOut: 1,
      pageSize: 4,
      inTime: null,
      outTime: null,
      insrcList: [],// 进场取证图片预览
      outsrcList: [],// 出场取证图片预览
      parkNameList: [],// 停车场列表
      selectedInRecord: {},// 入场一条数据
      selectedOutRecord: {},// 出场一条数据
      selectedInRecord2: {},// 追踪入场一条数据
      selectedOutRecord2: {},// 追踪出场一条数据
      upDetailDialogVisible: false,// 入口详情弹窗
      downDetailDialogVisible: false,// 出口详情弹窗
      upshow: 0,
      downshow: 0,
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      value1: '',
      value2: '',
      fit: 'fill',
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getInRecordList()
    this.getOutRecordList()
    this.getQueryDict()
  },
  //方法集合
  methods: {
    // 获取入场记录列表
    getInRecordList () {
      this.searchFormIn['pageNum'] = this.pageNumIn;
      this.searchFormIn['pageSize'] = this.pageSize;
      this.insrcList = []
      this.$carBeforeRecord.passRecord(this.searchFormIn).then(res => {
        this.tableList.list = res.resultEntity.content
        this.paginationConfig.total = res.resultEntity.totalElements
        if (this.tableList.list[0]) {
          this.selectedInRecord = this.tableList.list[0]
          this.insrcList.push(this.$imgBaseUrl + this.selectedInRecord.inImage)
        } else {
          this.selectedInRecord.inImage = ''
          this.insrcList = []
        }
      })
    },
    // 分页
    getList (changein) {
      this.pageNumIn = changein.pageNum
      this.pageNumIn = changein.pageNum
      this.getInRecordList();
    },
    // 查询
    handleClickInSearch () {
      this.pageNumIn = 1;
      if (this.inTime) {
        this.searchFormIn.inStartTime = this.inTime[0]
        this.searchFormIn.inEndTime = this.inTime[1]
      } else {
        this.searchFormIn.inStartTime = ''
        this.searchFormIn.inEndTime = ''
      }
      this.getInRecordList();
    },
    // 重置
    handleClickInReset () {
      this.searchFormIn = {}
      this.inTime = null
      this.getInRecordList()
    },
    // 获取出场记录列表
    getOutRecordList () {
      this.searchFormOut['pageNum'] = this.pageNumOut;
      this.searchFormOut['pageSize'] = this.pageSize;
      this.outsrcList = []
      this.$carBeforeRecord.passRecordOut(this.searchFormOut).then(res => {
        this.outTableList.list = res.resultEntity.content
        this.paginationConfigOut.total = res.resultEntity.totalElements
        if (this.outTableList.list[0]) {
          this.selectedOutRecord = this.outTableList.list[0]
          this.outsrcList.push(this.$imgBaseUrl + this.selectedOutRecord.outImage)
        } else {
          this.outsrcList = []
          this.selectedOutRecord.outImage = ''
        }
      })
    },
    // 分页
    getListOut (changeout) {
      this.pageNumOut = changeout.pageNum
      this.getOutRecordList()
    },
    //查询
    search () {
      this.pageNumout = 1;
      if (this.outTime) {
        this.searchFormOut.outStartTime = this.outTime[0]
        this.searchFormOut.outEndTime = this.outTime[1]
      } else {
        this.searchFormOut.outStartTime = ''
        this.searchFormOut.outEndTime = ''
      }
      this.getOutRecordList()
    },
    // 重置
    handleClickOutReset () {
      this.searchFormOut = {}
      this.outTime = null
      this.getOutRecordList()
    },
    //操作行
    //入场记录选中某行
    handleSelection (row) {
      this.selectedInRecord = row;
      console.log(this.selectedInRecord);
      this.insrcList = []
      this.insrcList.push(this.$imgBaseUrl + row.inImage)
    },
    //出场记录选中某行
    handleOutSelection (row) {
      this.selectedOutRecord = row;
      console.log(this.selectedOutRecord);
      this.outsrcList = []
      this.outsrcList.push(this.$imgBaseUrl + row.outImage)
    },
    //查看入场记录详情
    showDetail (item) {
      this.selectedInRecord = item;
      this.upDetailDialogVisible = true;
      this.insrcList = []
      this.insrcList.push(this.$imgBaseUrl + item.inImage)
    },
    //查看出场记录详情
    showDownDetail (item) {
      this.selectedOutRecord = item;
      this.downDetailDialogVisible = true;
      this.outsrcList = []
      this.outsrcList.push(this.$imgBaseUrl + item.outImage)
    },
    //入场记录追踪出场记录
    getOutRecord (orderSequence) {
      let info = {
        orderSequence: orderSequence,
        pageNum: 1,
        pageSize: 1
      }
      this.$carBeforeRecord.passRecord(info).then(res => {
        if (res.resultEntity.content[0].outTime == null) {
          this.$message({ type: "fail", message: "该车暂无出场记录!" });
          return;
        }
        this.downshow = 1;
        this.selectedOutRecord2 = res.resultEntity.content[0];
        this.outsrcList = []
        this.outsrcList.push(this.$imgBaseUrl + this.selectedOutRecord2.outImage)
      });
    },
    //出场记录追踪入场记录
    getInRecord (orderSequence) {
      let info = {
        "orderSequence": orderSequence,
        pageNum: 1,
        pageSize: 1
      }
      this.$carBeforeRecord.passRecord(info).then(res => {
        this.upshow = 1;
        this.selectedInRecord2 = res.resultEntity.content[0];
        this.insrcList = []
        this.insrcList.push(this.$imgBaseUrl + this.selectedInRecord2.inImage)
      });
    },
    //获取停车场名称
    getQueryDict () {
      let info = {
        columnName: ['park_id', 'park_name'], //有且只有两个 id放前面名称放后面
        tableName: 'tb_park',
        "whereStr": [
          {
            "colName": "park_type_code",
            "value": '2'
          }
        ]
      }
      this.$queryDict.queryDictWithAuth(info).then((response) => {
        this.parkNameList = response.resultEntity
      })
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 80px;
//@import url(); 引入公共css类
.mainBody {
  height: 100%;
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topButton {
      display: flex;
      margin-top: 3px;
    }
  }
  .tableRow {
    @include background('blockBg');
    width: 100%;
    height: 48%;
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
    }
    .tableContent {
      width: 100%;
      height: 80%;
      margin-top: 10px;
      display: flex;
      .image {
        width: 29%;
      }
      .table {
        width: 70%;
        margin-left: 1%;
      }
    }
  }
}
</style>

